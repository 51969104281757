var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Tabs',{attrs:{"value":"所有工单","animated":false}},[_c('TabPane',{attrs:{"label":"所有工单","name":"所有工单"}},[_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.tickets.all},scopedSlots:_vm._u([{key:"type",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row ? row.workflow_info ? row.workflow_info.workflow_name : "" : "")+" ")]}},{key:"receivers",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row ? row.participant_info ? row.participant_info.participant_alias : "无" : "无"))])]}},{key:"state",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.state.state_name))])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"primary","size":"small","ghost":""},on:{"click":function($event){return _vm.ticketsDetail(row)}}},[_vm._v("详 情")])]}}])})],1),_c('TabPane',{attrs:{"label":"待办工单","name":"待办工单"}},[_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.tickets.backlog},scopedSlots:_vm._u([{key:"type",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row ? row.workflow_info ? row.workflow_info.workflow_name : "" : "")+" ")]}},{key:"receivers",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row ? row.participant_info ? row.participant_info.participant_alias : "无" : "无"))])]}},{key:"state",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.state.state_name))])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"primary","size":"small","ghost":""},on:{"click":function($event){return _vm.showDetail(row)}}},[_vm._v("详 情")])]}}])})],1),_c('TabPane',{attrs:{"label":"申请工单","name":"申请工单"}},[_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.tickets.apply},scopedSlots:_vm._u([{key:"type",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row ? row.workflow_info ? row.workflow_info.workflow_name : "" : "")+" ")]}},{key:"receivers",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row ? row.participant_info ? row.participant_info.participant_alias : "无" : "无"))])]}},{key:"state",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.state.state_name))])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"primary","size":"small","ghost":""},on:{"click":function($event){return _vm.showDetail(row)}}},[_vm._v("详 情")])]}}])})],1),_c('Button',{staticStyle:{"margin-right":"8px"},attrs:{"slot":"extra","type":"primary","size":"small","icon":"ios-add","ghost":""},on:{"click":_vm.createTicket},slot:"extra"},[_vm._v("新建工单")]),_c('a',{attrs:{"slot":"extra"},on:{"click":_vm.refresh},slot:"extra"},[_c('Icon',{attrs:{"type":"ios-refresh"}}),_vm._v(" 刷新")],1)],1),_c('list-tab-details',{attrs:{"show":_vm.show,"details":_vm.details},on:{"cancel":_vm.cancel,"refresh":_vm.refresh}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }