<template>
  <div>
    <Tabs value="所有工单" :animated="false">
      <TabPane  label="所有工单" name="所有工单">
        <Table :columns="columns" :data="tickets.all">
          <template slot-scope="{ row }" slot="type">
            {{ row ? row.workflow_info ? row.workflow_info.workflow_name : "" : "" }}
          </template>
          <template slot-scope="{ row }" slot="receivers">
            <span>{{ row ? row.participant_info ? row.participant_info.participant_alias : "无"  : "无" }}</span>
          </template>
          <template slot-scope="{ row }" slot="state">
            <span>{{ row.state.state_name }}</span>
          </template>
          <template slot-scope="{ row }" slot="action">
            <Button @click="ticketsDetail(row)" type="primary" size="small" style="margin-right: 5px" ghost>详 情</Button>
          </template>
        </Table>
      </TabPane>
      <TabPane  label="待办工单" name="待办工单">
        <Table :columns="columns" :data="tickets.backlog">
          <template slot-scope="{ row }" slot="type">
            {{ row ? row.workflow_info ? row.workflow_info.workflow_name : "" : "" }}
          </template>
          <template slot-scope="{ row }" slot="receivers">
            <span>{{ row ? row.participant_info ? row.participant_info.participant_alias : "无"  : "无" }}</span>
          </template>
          <template slot-scope="{ row }" slot="state">
            <span>{{ row.state.state_name }}</span>
          </template>
          <template slot-scope="{ row }" slot="action">
            <Button @click="showDetail(row)" type="primary" size="small" style="margin-right: 5px" ghost>详 情</Button>
          </template>
        </Table>
      </TabPane>
      <TabPane  label="申请工单" name="申请工单">
        <Table :columns="columns" :data="tickets.apply">
          <template slot-scope="{ row }" slot="type">
            {{ row ? row.workflow_info ? row.workflow_info.workflow_name : "" : "" }}
          </template>
          <template slot-scope="{ row }" slot="receivers">
            <span>{{ row ? row.participant_info ? row.participant_info.participant_alias : "无"  : "无" }}</span>
          </template>
          <template slot-scope="{ row }" slot="state">
            <span>{{ row.state.state_name }}</span>
          </template>
          <template slot-scope="{ row }" slot="action">
            <Button @click="showDetail(row)" type="primary" size="small" style="margin-right: 5px" ghost>详 情</Button>
          </template>
        </Table>
      </TabPane>
      <Button
          type="primary"
          size="small"
          icon="ios-add"
          slot="extra" ghost
          style="margin-right: 8px"
          @click="createTicket"
      >新建工单</Button>
      <a slot="extra" @click="refresh"><Icon type="ios-refresh" /> 刷新</a>
    </Tabs>
    <list-tab-details :show="show" :details="details" @cancel="cancel" @refresh="refresh"></list-tab-details>
  </div>
</template>

<script>
import {get} from "@/api/http";
import ListTabDetails from "@/View/tickets/ListTabDetails";
export default {
  name: "TicketsTab",
  props: {
    tickets: Object,
    default: function () {
      return {all :[], owner: [], backlog: [], apply: []}
    }
  },
  components: {
    ListTabDetails
  },
  data(){
    return {
      show: false,
      details: {
        flowsteps: {
          current_state_id: null,
          current: 0,
          value: []
        },
        flowlogs: [],
        details: {}
      },
      ticketOperation: {
        state_id: null,
        suggestion: ""
      },
      columns: [
        {
          title: 'id',
          width: 60,
          key: 'id'
        },
        {
          title: '流水号',
          key: 'sn'
        },
        {
          title: '类型',
          width: 195,
          slot: 'type'
        },
        {
          title: '标题',
          width: 190,
          key: 'title'
        },
        {
          title: '创建人',
          width: 100,
          key: 'creator'
        },
        {
          title: '受理人',
          width: 220,
          slot: 'receivers'
        },
        {
          title: '当前状态',
          // width: 110,
          slot: 'state',
        },
        {
          title: '创建时间',
          width: 170,
          key: 'gmt_created',
          sortable: true,
        },
        {
          title: '更新时间',
          width: 170,
          key: 'gmt_modified',
          sortable: true,
        },
        {
          title: '操 作',
          align: 'left',
          width: 80,
          fixed: 'right',
          slot: 'action',
        }
      ],
    }
  },
  methods: {
    refresh() {
      this.$emit("getTickets")
    },
    createTicket() {},
    ticketsDetail(row) {
      this.details.details = row
      get(`/api/v1.0/tickets/${row.id}/flowsteps`)
          .then((resp)=>{
            this.details.flowsteps = resp.data;
            let current_state_id = this.details.flowsteps.current_state_id;
            let steps = this.details.flowsteps.value;
            for (let item of steps.entries()) {
              if (item[1].state_id === current_state_id) {
                this.details.flowsteps.current = item[0]
              }
            }
          })
          .catch((e)=>{
            console.log(e)
          })
      get(`/api/v1.0/tickets/${row.id}/flowlogs`)
          .then((resp)=>{
            this.details.flowlogs = resp.data.value;
          })
          .catch((e)=>{
            console.log(e)
          })
      get(`/api/v1.0/tickets/${row.id}`)
          .then((resp)=>{
            console.log(resp)
            this.details.details = resp.data.value;
          })
          .catch((e)=>{
            console.log(e)
          })
      this.show = true;
    },
    cancel(v) {
      this.show = v;
    }
  }
}
</script>

<style scoped>

</style>