<template>
  <div>
    <Drawer
        :title="details.details.title"
        v-model="show"
        :width="800"
        :mask-closable="false"
        :closable="false"
        :styles="styles"
    >
      <Card :bordered="true" style="margin-bottom: 16px" dis-hover>
        <p slot="title">工单进度</p>
        <Row>
          <Col span="24">
            <Steps :current="details.flowsteps.current">
              <Step v-for="item in details.flowsteps.value" :title="item.state_name" :content="''"  :key="item._index"></Step>
            </Steps>
          </Col>
        </Row>
      </Card>
      <Card :bordered="true" style="margin-bottom: 16px" dis-hover>
        <p slot="title">工单信息</p>
        <Row v-for="item in details.details.field_list" :key="item._index">
          <Col span="4">{{item.field_name}}:</Col>
          <Col span="20">{{item.field_value}}</Col>
        </Row>
      </Card>
      <Card :bordered="true" style="margin-bottom: 16px" dis-hover>
        <p slot="title">操作记录</p>
        <Steps :current="0" direction="vertical">
          <Step v-for="item in details.flowlogs" :title="item.participant" :content="`${item.gmt_created} ${item.transition.transition_name} 意见: ${item.suggestion}`" :key="item._index"></Step>
        </Steps>
      </Card>
      <Divider orientation="left">评 论</Divider>
      <Form ref="ticketOperation" :model="ticketOperation">
        <Input v-model="ticketOperation.comments" type="textarea" :rows="4" placeholder="评 论" />
        <br><br>
        <Button type="primary" @click="handleComments" long>提 交</Button>
      </Form>

      <div class="demo-drawer-footer">
        <Button slot="drawer-footer" @click="cancel" style="margin-right: 5px">取 消</Button>
        <Button slot="drawer-footer" @click="show1 = true" type="primary" style="margin-right: 5px" ghost>关闭工单</Button>
        <Button slot="drawer-footer" @click="show2 = true" type="primary" style="margin-right: 5px" ghost>修改状态</Button>
        <!--    <Button slot="drawer-footer" @click="closeWorder" type="primary" style="margin-right: 5px" ghost>强制转交</Button>-->
      </div>
    </Drawer>
    <!-- 强制关闭工单 -->
    <Modal
        title="强制关闭工单"
        v-model="show1"
        @on-ok="handleCloseTicket"
        :mask-closable="false">
      <Form ref="ticketOperation" :model="ticketOperation">
        <FormItem prop="suggestion">
          <Input v-model="ticketOperation.suggestion" type="textarea" :rows="4" placeholder="备注或意见" />
        </FormItem>
      </Form>
    </Modal>

    <!-- 强制修改状态 -->
    <Modal
        title="强制修改状态"
        v-model="show2"
        @on-ok="handleStateTicket"
        :mask-closable="false">
      <Form ref="ticketOperation" :model="ticketOperation">
        <FormItem prop="state_id">
          <RadioGroup v-model="ticketOperation.state_id">
            <Radio v-for="item in details.flowsteps.value" :label="item.state_id" :key="item._index">{{item.state_name}}</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem prop="suggestion">
          <Input v-model="ticketOperation.suggestion" type="textarea" :rows="4" placeholder="备注或意见" />
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import {post, put} from "@/api/http";
import Notice from "@/components/baseNotice"

export default {
  name: "ListTabDetails",
  props: {
    show: Boolean,
    details: {
      type: Object,
      default: function () {
        return {
          flowsteps: {
            current_state_id: null,
            current: 0,
            value: []
          },
          flowlogs: [],
          details: {
            title: '',
            field_list: []
          }
        }
      }
    }
  },
  data () {
    return {
      show1: false,
      show2: false,
      tempDetails: this.details,
      ticketOperation: {
        comments: "",
        state_id: null,
        suggestion: ""
      },
      styles: {
        height: 'calc(100% - 100px)',
        overflow: 'auto',
        paddingBottom: '53px',
        position: 'static'
      },
    }
  },
  methods: {
    resetTicketOperation() {
      this.ticketOperation = {
        comments: "",
        state_id: null,
        suggestion: ""
      }
    },
    handleComments() {
      post(`/api/v1.0/tickets/${this.tempDetails.details.id}/comments`, {suggestion: this.ticketOperation.comments})
      .then(resp=>{
        Notice.success("工单操作", "评论成功")
        this.resetTicketOperation()
        console.log(resp)
      })
    },
    handleCloseTicket() {
      post(`/api/v1.0/tickets/${this.tempDetails.details.id}/close`, {suggestion: this.ticketOperation.suggestion})
          .then((resp)=>{
            this.show1 = false
            this.cancel()
            this.resetTicketOperation()
            this.$emit("refresh")
          })
          .catch((e)=>{
            console.error(e)
          })
    },
    handleStateTicket() {
      let {state_id, suggestion} = this.ticketOperation;
      put(`/api/v1.0/tickets/${this.tempDetails.details.id}/state`, {state_id: state_id ,suggestion: suggestion})
          .then((resp)=>{
            this.show2 = false
            this.cancel()
            this.resetTicketOperation()
            this.$emit("refresh")
          })
          .catch((e)=>{
            console.log(e)
          })
    },
    cancel() {
      this.$emit("cancel", false)
    },
  }
}
</script>

<style scoped>
.demo-drawer-footer{
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
</style>